/* src/styles/image-gallery-custom.css */

.custom-image-gallery-container {
    background-color: #ffffff;
    border-radius: 16px;
    max-width: 50%;
    transform: scale(90%);
    margin: 0 auto;
}

.image-gallery-icon {
    filter: drop-shadow(0 1px 1px #4a4a4a);
    stroke-width: 2;

    svg polyline {
        transition: ease all 100ms;
        stroke-width: 2;
        stroke: #ffffff;
        /*filter: drop-shadow(0 0 0 #101010);*/
    }

    &:hover svg polyline {
        stroke: #ffffff;
    }
}

.image-gallery-fullscreen-button {
    svg path {
        stroke: #f6f6f6;
        stroke-width: 2.5;
    }

    &:hover svg path {
        stroke: #ffffff;
    }
}

.image-gallery-bullet {
    border: 1px solid #252525 !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05) !important;
    background: white !important;
    width: 15px;
    height: 15px;

    &:hover {
        border: 1px solid #000000;
        transform: scale(110%)
    }
}

.image-gallery-bullet.active {
    transform: scale(130%) !important;
}

.image-gallery-thumbnail {
    border: 3px solid transparent !important;
    transition: all ease 200ms;

    &.active {
        border: 3px solid #202020 !important;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05) !important;
    }
}
